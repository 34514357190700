<template>
  <div
    v-if="showMessage"
    :class="{
      'global-error': true,
      'slide-in-blurred-top': showMessage,
      'slide-out-blurred-top': !showMessage
    }"
  >
    <div>
      <div :class="'alert mb-0 alert-' + toastMessage.type" role="alert">
        <div class="alert-text" v-html="toastMessage.message"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  data () {
    return {
      showMessage: false,
      timeout: null
    }
  },
  watch: {
    toastMessage (newValue) {
      if (newValue.message) {
        this.showMessage = true
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.showMessage = false
          this.$store.commit('Message/SET_MESSAGE', {
            message: null,
            type: null
          })
        }, 5000)
      }
    }
  },
  computed: {
    toastMessage () {
      return this.$store.getters['Message/get']
    }
  }
}
</script>

<style>
  .alert-danger {
    color: white !important;
    background-color: red !important;
    border-color: red !important;
  }
  .alert-success {
    color: white !important;
    background-color: #07b207 !important;
    border-color: #07b207 !important;
  }
  .alert-warning {
    color: black !important;
    background-color: yellow !important;
    border-color: yellow !important;
  }
  .global-error {
    position: fixed;
    width: 400px;
    text-align: center;
    left: calc(50% - 200px);
    top: 4%;
    z-index: 9999;
    box-shadow: 0px 0px 5px #000;
    border-radius: 4px;
  }

  .slide-in-blurred-top {
    -webkit-animation: slide-in-blurred-top 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
    animation: slide-in-blurred-top 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
  }

  .slide-out-blurred-top {
    -webkit-animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06)
      both;
    animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  }

  @-webkit-keyframes slide-in-blurred-top {
    0% {
      -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-top {
    0% {
      -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-out-blurred-top {
    0% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }
  }
  @keyframes slide-out-blurred-top {
    0% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }
  }
</style>
