<template>
    <div class="option-container" :class="{ 'compress': compressed }">
        <div v-if="level === 1" @click="goBack" class="option" :class="{ 'compress': compressed }">
            <i class="pi pi-chevron-left"></i>
            <span>Back</span>
        </div>

        <div v-for="option in (level === 0 ? structured : childrens)" :key="option.code" class="option"
            @click="selectOption(option)" :class="{ 'compress': compressed }">
            <component :is="option.icon" />
            <span :class="{ 'active': activeOption === option.code }">{{ option.label }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SideOptions',
    props: {
        structured: {
            required: true,
            type: Array
        },
        compressed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeOption: null,
            level: 0,
            childrens: []
        }
    },
    methods: {
        selectOption(option) {
            if (option.childrens) {
                this.level = 1;
                this.childrens = option.childrens;
            } else if (option.action) {
                option.action();
            }
            this.activeOption = option.code;
        },
        goBack() {
            this.level = 0;
            this.childrens = [];
        }
    }
}
</script>

<style scoped>
.option-container {
    padding: 20px;
    display: grid;
    gap: 16px;
    /* color: #C0C2C6; */
}

.option-container .option {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    flex-wrap: wrap;
    flex-grow: 1;
    font-size: 16px;
}

.option-container .option:hover {
    color: #000000;
}

.option-container .option .active {
    color: #000000;
}

.option-container .option.compress {
    justify-content: center;
    font-size: 12px;
    gap: 8px;
}

.option-container.compress {
    padding: 20px 6px;
}
</style>