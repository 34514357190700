import API from './api'
import store from '@/store'

function rejectWithErr (err) {
  let txt = 'Error'
  if (err?.response?.data?.errors) {
    txt = err.response?.data.errors.join('<br>')
  } else if (err?.response?.data?.message){
    txt = err?.response?.data?.message
  }
  store.commit('Message/SET_MESSAGE', {type: 'warning', message: txt})
  return Promise.reject(txt)
}

export default {

  authenticate (data) {
    return new Promise((resolve) => {
      API.post('login', data).then((response) => {
        if (response && response.data.user) {
          this.setUser(response.data.user)
          if (response.data.user.type === 'user') {
            store.commit('User/SET_ACCESS_MODULES', response.data.access_modules)
          }
          if (response.data.limits) {
            store.commit('User/SET_ACCOUNT_LIMITS', response.data.limits)
          }
          resolve(response.data.user)
        }
        // resolve(false)
      }).catch(rejectWithErr)
    })
  },
  logout (data) {
    return new Promise((resolve) => {
      API.get('logout', data).then(() => {
        store.dispatch('reset')
        resolve()
      }).catch(rejectWithErr)
    })
  },
  register (data) {
    return new Promise((resolve) => {
      API.post('register', data).then(response => {
        if (response.data.user) {
          this.setUser(response.data.user)
          if (response.data.user.type === 'user') {
            store.commit('User/SET_ACCESS_MODULES', response.data.access_modules)
          }
          resolve(response.data.user)
        } else {
          resolve(false)
        }
      }).catch(rejectWithErr)
    })
  },
  setUser (user) {
    if (user) {
      store.commit('User/SET_LOGIN_STATUS', true)
    } else {
      store.commit('User/SET_LOGIN_STATUS', false)
    }
    store.commit('User/SET_USER', user)
  },
  validateToken () {
    return new Promise((resolve) => {
      // TO DO reject
      resolve()
    })
  },
  get (url) {
    return new Promise((resolve) => {
      API.get(url).then(response => {
        resolve(response?.data)
      }).catch(rejectWithErr)
    })
  },
  post (url, data, header = {}) {
    return new Promise((resolve) => {
      API.post(url, data, {
        headers: header
      }).then(response => {
        resolve(response.data)
      }).catch(rejectWithErr)
    })
  },
  currency (amount) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })

    return formatter.format(amount)
  }
}
