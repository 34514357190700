<template>
    <svg  width="22" height="22" viewBox="0 0 22 22" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_850_19125)">
            <path
                d="M2.5 22C1.8125 22 1.22417 21.7608 0.735 21.2825C0.245833 20.8042 0.000833333 20.2286 0 19.5555V2.44444C0 1.77222 0.245 1.19696 0.735 0.718666C1.225 0.24037 1.81333 0.000814814 2.5 0H10C10.3542 0 10.6513 0.117333 10.8913 0.352C11.1313 0.586666 11.2508 0.87674 11.25 1.22222C11.2492 1.5677 11.1292 1.85818 10.89 2.09366C10.6508 2.32915 10.3542 2.44607 10 2.44444H2.5V19.5555H10C10.3542 19.5555 10.6513 19.6729 10.8913 19.9075C11.1313 20.1422 11.2508 20.4323 11.25 20.7778C11.2492 21.1232 11.1292 21.4137 10.89 21.6492C10.6508 21.8847 10.3542 22.0016 10 22H2.5ZM17.7188 12.2222H8.75C8.39583 12.2222 8.09917 12.1049 7.86 11.8702C7.62083 11.6355 7.50083 11.3455 7.5 11C7.49917 10.6545 7.61917 10.3644 7.86 10.1298C8.10083 9.8951 8.3975 9.77777 8.75 9.77777H17.7188L15.375 7.48611C15.1458 7.26203 15.0312 6.98703 15.0312 6.66111C15.0312 6.33518 15.1458 6.05 15.375 5.80555C15.6042 5.56111 15.8958 5.43359 16.25 5.423C16.6042 5.4124 16.9062 5.52974 17.1562 5.775L21.625 10.1444C21.875 10.3889 22 10.6741 22 11C22 11.3259 21.875 11.6111 21.625 11.8555L17.1562 16.225C16.9062 16.4694 16.6096 16.5868 16.2663 16.577C15.9229 16.5672 15.6258 16.4397 15.375 16.1944C15.1458 15.95 15.0367 15.6599 15.0475 15.3242C15.0583 14.9885 15.1779 14.7082 15.4062 14.4833L17.7188 12.2222Z"
                fill="url(#paint0_linear_850_19125)" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_850_19125" x1="-2.21733e-07" y1="10.9325" x2="22" y2="10.9325"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A3494" />
                <stop offset="0.0549206" stop-color="#4F3795" />
                <stop offset="0.0924392" stop-color="#583C98" />
                <stop offset="0.2" stop-color="#6A479C" />
                <stop offset="0.364138" stop-color="#81499D" />
                <stop offset="0.450668" stop-color="#94469B" />
                <stop offset="0.5" stop-color="#A14299" />
                <stop offset="0.647401" stop-color="#CC3293" />
                <stop offset="0.780331" stop-color="#EE236C" />
                <stop offset="0.818455" stop-color="#EF4B5E" />
                <stop offset="0.859048" stop-color="#F2624E" />
                <stop offset="0.915121" stop-color="#F57E27" />
                <stop offset="0.953787" stop-color="#F7932D" />
                <stop offset="1" stop-color="#FBAE48" />
            </linearGradient>
            <clipPath id="clip0_850_19125">
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'LogoutIcon',
}
</script>