<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="4" fill="#AF4E6F" fill-opacity="0.1" />
        <g clip-path="url(#clip0_850_17795)">
            <path
                d="M19 17.7778C19.6722 17.7778 20.2479 17.5386 20.727 17.0603C21.2061 16.582 21.4452 16.0064 21.4444 15.3333C21.4436 14.6603 21.2045 14.085 20.727 13.6076C20.2495 13.1301 19.6738 12.8905 19 12.8889C18.3261 12.8873 17.7509 13.1268 17.2742 13.6076C16.7976 14.0883 16.558 14.6636 16.5555 15.3333C16.5531 16.0031 16.7927 16.5788 17.2742 17.0603C17.7558 17.5419 18.331 17.781 19 17.7778ZM12.8889 17.5333V12.8889H10.4444V14.1111C10.4444 14.8852 10.6685 15.5831 11.1167 16.2048C11.5648 16.8265 12.1556 17.2693 12.8889 17.5333ZM25.1111 17.5333C25.8444 17.2685 26.4352 16.8253 26.8833 16.2035C27.3315 15.5818 27.5555 14.8844 27.5555 14.1111V12.8889H25.1111V17.5333ZM17.7778 27.5555V23.7667C16.7796 23.5426 15.8886 23.1201 15.1048 22.4992C14.3209 21.8783 13.7453 21.099 13.3778 20.1611C11.85 19.9778 10.572 19.3108 9.54367 18.1603C8.51537 17.0098 8.00081 15.6601 8 14.1111V12.8889C8 12.2167 8.23956 11.6414 8.71867 11.1631C9.19778 10.6848 9.77304 10.4453 10.4444 10.4444H12.8889C12.8889 9.77222 13.1284 9.19696 13.6076 8.71867C14.0867 8.24037 14.6619 8.00081 15.3333 8H22.6667C23.3389 8 23.9145 8.23956 24.3937 8.71867C24.8728 9.19778 25.1119 9.77304 25.1111 10.4444H27.5555C28.2278 10.4444 28.8034 10.684 29.2825 11.1631C29.7617 11.6422 30.0008 12.2175 30 12.8889V14.1111C30 15.6593 29.4854 17.009 28.4563 18.1603C27.4272 19.3117 26.1492 19.9786 24.6222 20.1611C24.2555 21.0981 23.6803 21.8775 22.8964 22.4992C22.1126 23.1209 21.2212 23.5434 20.2222 23.7667V27.5555H23.8889C24.2352 27.5555 24.5257 27.6729 24.7603 27.9075C24.995 28.1422 25.1119 28.4323 25.1111 28.7778C25.1103 29.1232 24.993 29.4137 24.7591 29.6492C24.5252 29.8847 24.2352 30.0016 23.8889 30H14.1111C13.7648 30 13.4747 29.8827 13.2409 29.648C13.007 29.4133 12.8897 29.1232 12.8889 28.7778C12.8881 28.4323 13.0054 28.1422 13.2409 27.9075C13.4764 27.6729 13.7664 27.5555 14.1111 27.5555H17.7778Z"
                fill="#AF4E6F" />
        </g>
        <defs>
            <clipPath id="clip0_850_17795">
                <rect width="22" height="22" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'RewardIcon',
}
</script>