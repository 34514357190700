export default {
  types () {
    return {
      puzzle: {
        name: 'Puzzle',
        icon: 'Puzzle.png'
      },
      emojiq: {
        name: 'EmojiQ',
        icon: 'Emojiq.png'
      },
      wordhunt: {
        name: 'Wordhunt',
        icon: 'Wordhunt.png'
      },
      quiz: {
        name: 'Quiz',
        icon: 'Quiz.png'
      },
      scratch: {
        name: 'ScratchMe',
        icon: 'Scratch.png'
      },
      wordle: {
        name: 'Wordle',
        icon: 'Wordle.png'
      }
    }
  },
  popup_emoji: {
    win: 'https://int.klujo.com/klujo_public/includes/images/happy.png',
    fail: 'https://int.klujo.com/klujo_public/includes/images/sad.png'
  },
  points: {
    easy: 10,
    normal: 30,
    hard: 50
  },
  puzzle: {
    backgroundImage: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/game_bg.png',
    gameImage: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/Scratch_default.svg',
    tiles: {
      easy: { horizontal: 3, vertical: 3 },
      normal: { horizontal: 4, vertical: 4 },
      hard: { horizontal: 5, vertical: 5 }
    },
    time: {
      easy: 120,
      normal: 240,
      hard: 480
    },
    fail_popup_text: 'Game over',
    win_popup_text: 'Congratulations.',
    solvePoints: 5,
    call_to_action_icon: '/img/fg-forward.png'
  },
  emojiq: {
    backgroundImage: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/EmojiQ_Game_Background.png',
    lives: {
      easy: 8,
      normal: 6,
      hard: 4
    },
    fail_popup_text: 'Game over',
    win_popup_text: 'Congratulations.',
    call_to_action_icon: '/img/fg-forward.png'
  },
  wordhunt: {
    time: {
      easy: 120,
      normal: 90,
      hard: 60
    },
    solvePoints: 5,
    fail_popup_text: 'Game over',
    backgroundImage: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/game_bg.png',
    call_to_action_icon: '/img/fg-external.png'
  },
  quiz: {
    backgroundImage: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/game_bg.png',
    time: 60,
    points: 5,
    fail_popup_text: 'Game over',
    win_popup_text: 'Congratulations.',
    call_to_action_icon: '/img/fg-forward.png'
  },
  scratch: {
    backgroundImage: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/game_bg.png',
    gameImage: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/Scratch_default.svg',
    mouse_cursor: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/scratch_mouse_cursor.png',
    time: 60,
    points: 5,
    fail_popup_text: 'Game over',
    win_popup_text: 'Congratulations.',
    call_to_action_icon: '/img/fg-forward.png'
  },
  wordle: {
    backgroundImage: import.meta.env.VITE_MEDIA_BASE_URL + 'assets/images/EmojiQ_Game_Background.png',
    points: 10,
    time: {
      easy: 120,
      normal: 90,
      hard: 60
    },
    fail_popup_text: 'Game over',
    win_popup_text: 'Congratulations.',
    call_to_action_icon: '/img/fg-forward.png'
  },
  editor: {
    theme: 'bubble',
    placeholder: 'Insert game instructions here...',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ script: 'sub' }, { script: 'super' }],
        [{ align: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }], [{ font: [] }],
        [{ color: [] }, { background: [] }]
      ]
    }
  },
  routes: {
    games: 'GameList',
    rewards: 'RewardList',
    integrations: 'Integrations',
    account_managment: 'Subscription',
    teams: 'TeamMates',
    domains: 'Domains'
  },
  footer: {
    timer: {
      text: 'Timer',
      back_color: '#48C0F9'
    },
    letter: {
      text: 'Letter',
      back_color: '#48C0F9'
    },
    leaderboard: {
      text: 'Leader Board',
      back_color: '#24242c'
    },
    hint: {
      text: 'Hint',
      back_color: '#48C0F9'
    },
    reset: {
      text: 'Reset',
      back_color: '#FFDE10'
    },
    solve: {
      text: 'Solve',
      back_color: '#48C0F9'
    },
    share: {
      text: 'Share',
      back_color: '#f21d1d'
    },
    change_my_avtar: {
      text: 'Change avatar',
      back_color: '#FFDE10'
    },
    start_game: {
      text: 'Start',
      back_color: '#48C0F9'
    },
    call_to_action: {
      text: 'Call to action',
      back_color: '#48C0F9'
    },
    lives: {
      text: 'Lives',
      back_color: '#48C0F9'
    },
    info: ''
  }
}
