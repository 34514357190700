const initialState = () => ({
  errors: {},
  message: {
    loading: null
  }
})
const state = initialState()

const actions = {}

const getters = {
  get (state) { return state.loading }
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_LOADDING (state, loading) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
