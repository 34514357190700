<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="4" fill="#43BEDD" fill-opacity="0.1" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M28.6263 21.2815L29.5654 22.009C30.0111 22.3483 30.1301 22.9403 29.8472 23.4088L28.4855 25.6132C28.2038 26.0424 27.6479 26.2377 27.1353 26.0871L25.9615 25.6903C25.2686 25.4471 24.4944 25.5249 23.8719 25.8997C23.5959 26.0788 23.3054 26.2371 23.0031 26.3737C22.3425 26.6918 21.881 27.2844 21.7587 27.9718L21.5592 29.0741C21.4662 29.6131 20.9668 30.0069 20.3852 29.9999H17.6852C17.1036 30.0069 16.6043 29.6131 16.5112 29.0741L16.3117 27.9718C16.1893 27.2844 15.7279 26.6918 15.0673 26.3737C14.765 26.2371 14.4745 26.0788 14.1986 25.8997C13.576 25.5249 12.8018 25.4471 12.1089 25.6903L10.9351 26.0871C10.4017 26.2732 9.80314 26.0735 9.51451 25.6132L8.15285 23.4088C7.86987 22.9403 7.98893 22.3483 8.43455 22.009L9.37375 21.2815C9.94317 20.8377 10.2564 20.1679 10.2189 19.474V19V18.5261C10.2564 17.8321 9.94317 17.1622 9.37375 16.7185L8.43455 15.991C7.98893 15.6517 7.86987 15.0597 8.15285 14.5913L9.51451 12.3868C9.80095 11.9372 10.3851 11.739 10.9115 11.9129L12.0856 12.3096C12.7783 12.5527 13.5526 12.4752 14.1751 12.1003C14.4512 11.9213 14.7416 11.7627 15.0437 11.6264C15.6863 11.2978 16.1292 10.7069 16.2413 10.0281L16.4408 8.92595C16.5338 8.38691 17.0332 7.99307 17.6148 8.00009H20.3148C20.8964 7.99307 21.3957 8.38691 21.4888 8.92595L21.6883 10.0281C21.8107 10.7156 22.2721 11.3083 22.9327 11.6264C23.235 11.7627 23.5255 11.9213 23.8014 12.1003C24.424 12.4752 25.1982 12.5527 25.8911 12.3096L27.0649 11.9129C27.5983 11.7268 28.1969 11.9266 28.4855 12.3868L29.8472 14.5913C30.1301 15.0597 30.0111 15.6517 29.5654 15.991L28.6263 16.7185C28.0568 17.1622 27.7436 17.8321 27.7811 18.5261V19V19.474C27.7436 20.1679 28.0568 20.8377 28.6263 21.2815ZM15.4781 19.0002C15.4781 20.8264 17.0549 22.3068 18.9998 22.3068C19.9339 22.3068 20.8297 21.9584 21.4903 21.3382C22.1507 20.7181 22.5218 19.8772 22.5218 19.0002C22.5218 17.174 20.9449 15.6936 18.9998 15.6936C17.0549 15.6936 15.4781 17.174 15.4781 19.0002Z"
            fill="#00CCFF" />
    </svg>
</template>

<script>
export default {
    name: 'SettingsIcon',
}
</script>