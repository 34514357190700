import Storage from '@/helper/Storage'
import API from '@/api/http'
import store from '@/store'

const initialState = () => ({
  errors: {},
  isLoggedin: false,
  isFirstLoad: true,
  user: {},
  user_limits: {},
  access_modules: {},
  login_success_route: import.meta.env.VITE_LOGIN_SUCCESS_ROUTE
})

const state = initialState()

const actions = {
  getAuth ({ state, commit }) {
    try {
      const rejectError = new Error('Please login to access.')
      return new Promise((resolve, reject) => {
        const isLoggedin = Storage.Get('isLoggedin', false, 'bool')
        state.isLoggedin = isLoggedin
        if (state.isFirstLoad) {
          commit('SET_FIRST_LOAD_STATUS', false)
          if (!isLoggedin) {
            reject(rejectError)
          } else {
            state.user = Storage.Get('user', state.user, 'object')
            API.get('user-profile', state.user.token)
              .then((response) => {
                API.setUser(response.user)
                if (response.user.type === 'user') {
                  store.commit('User/SET_ACCESS_MODULES', response.access_modules)
                }
                if (response.limits) {
                  store.commit('User/SET_ACCOUNT_LIMITS', response.limits)
                }
                if (response.game_settings) {
                  store.commit('Games/SET_SETTINGS', response.game_settings)
                }
                resolve(response.user)
              }).catch((error) => {
                // error
                console.log({ error })
                reject(rejectError)
              })
          }
        } else if (isLoggedin) {
          state.user = Storage.Get('user', state.user, 'object')
          resolve(state.user)
        } else {
          reject(rejectError)
        }
      })
    } catch (error) {
      console.log({ error })
    }
  },
  getAccessModules ({ state }) {
    return new Promise((resolve) => {
      resolve(state.access_modules)
    })
  },
  setAuthFromLocal ({ state }) {
    return new Promise((resolve) => {
      if (state.isFirstLoad) {
        // commit('SET_FIRST_LOAD_STATUS', false)
        state.user = Storage.Get('user', state.user, 'object')
        state.isLoggedin = Storage.Get('isLoggedin', state.isLoggedin, 'bool')
        resolve()
      } else {
        resolve()
      }
    })
  }
}

const getters = {
  user (state) { return state.user },
  user_limits (state) { return state.user_limits },
  access_modules (state) { return state.access_modules },
  isLoggedin (state) { return state.isLoggedin },
  isFirstLoad (state) { return state.isFirstLoad }
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_USER (state, user) {
    Storage.Set('user', user)
    state.user = user
  },
  SET_ACCOUNT_LIMITS (state, limits) {
    Storage.Set('user_limits', limits)
    state.user_limits = limits
  },
  SET_ACCESS_MODULES (state, access_modules) {
    Storage.Set('access_modules', access_modules)
    state.access_modules = access_modules
  },
  SET_LOGIN_STATUS (state, flag) {
    Storage.Set('isLoggedin', flag)
    state.isLoggedin = flag
  },
  SET_FIRST_LOAD_STATUS (state, flag) {
    // Storage.Set('isFirstLoad', flag)
    state.isFirstLoad = flag
  },
  SET_LOGIN_SUCCESS_ROUTE (state, route) {
    state.login_success_route = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
