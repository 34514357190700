<template>
    <nav ref="sidebar" id="sidebar" class="admin-navbar"
        :style="{ width: expand ? sidebarExpandedWidth : sidebarCollapsedWidth }">

        <!-- Sidebar logo -->
        <div class="sidebar-logo" @click="expandSidebar">
            <div class="site-logo text-center my-4">
                <img v-if="expand" :src="logo" alt="Klujo">
                <img v-else :src="mobileLogo" alt="Klujo">
            </div>
        </div>

        <!-- Sidebar profile -->
        <div class="sidebar-profile">
            <div class="footer-item">
                <!--
                <router-link :to="{ name: 'Dashboard' }" class="email-init">
                    <span class="h3 border rounded-circle m-0"> {{ user.email.charAt(0) }} </span>
                </router-link>
                -->

                <div @click="toggle" class="email-init" style="cursor: pointer;">
                    <span class="h3 border rounded-circle m-0"> {{ user.email.charAt(0) }} </span>
                </div>

                <div v-if="shouldShowText">
                    <strong v-if="user.current_account">{{ user.current_account.business_name }}</strong>
                    <div class="user-email">{{ user.email }}</div>
                </div>
            </div>

            <i v-if="shouldShowText" style="cursor: pointer;" class="pi pi-chevron-down" @click="toggle"></i>
        </div>

        <!-- Popover -->
        <Popover ref="op">
            <div class="popover">
                <strong v-if="user.current_account">{{ user.current_account.business_name }}</strong>
                <div class="user-email">{{ user.email }}</div>

                <hr class="w-100 my-0">

                <div v-for="(account, index) in user.related_accounts" :key="index">
                    <a href="javascript:void(0)" @click="changeAccount(account.public_id)" class="popover__item">
                        <i class="las la-user-tie h5"></i>
                        <span>{{ account.business_name }}</span>
                    </a>
                </div>

                <router-link v-if="(user.type == 'user' && typeof access_modules['account_managment'] != 'undefined') ||
                    user.type == 'account'" :class="{ 'border-top': user.related_accounts.length > 0 }"
                    :to="{ name: 'Upgrade' }" class="popover__item">
                    <i class="pi pi-chart-bar"></i>
                    <span>Upgrade</span>
                </router-link>

                <router-link v-if="user.type == 'account'" :to="{ name: 'Subscription' }" class="popover__item">
                    <i class="las la-briefcase h5"></i>
                    <span>Subscription</span>
                </router-link>

                <router-link class="popover__item" :to="{ name: 'TeamMates' }"
                    v-if="(typeof access_modules['teams'] != 'undefined' && user.type == 'user') || user.type == 'account'">
                    <i class="pi pi-users"></i>
                    <span>Team Mates</span>
                </router-link>

                <a href="#" class="popover__item">
                    <i class="pi pi-bell"></i>
                    <span>Notification</span>
                </a>

                <a href="https://klujo.freshdesk.com/support/home" target="_blank" class="popover__item">
                    <i class="pi pi-question-circle"></i>
                    <span>Support</span>
                </a>
            </div>
        </Popover>

        <!-- Sidebar menu -->
        <div class="sidebar__menu">
            <SideOptions :structured="sideStructured" :compressed="!expand" />
        </div>

        <!-- Footer -->
        <div v-if="showLoginElements" class="sidebar__footer">
            <router-link class="footer-item" :to="{ name: 'AddAccount' }" v-if="(user.type == 'account' || user.type == 'child_account') &&
                ((Object.keys(user.related_accounts).length === 0 && user.plan.sub_accounts > 1) ||
                    user.related_accounts.length < user.plan.sub_accounts)">
                <i class="fa fa-user-plus"></i>
                <span v-if="shouldShowText">Add Account</span>
            </router-link>

            <a href="javascript:void(0);" @click="logout" class="popover__item"
                :style="{ justifyContent: !shouldShowText ? 'center' : 'start' }">
                <LogoutIcon />
                <span v-if="shouldShowText">Logout</span>
            </a>
        </div>
    </nav>
</template>

<script>
import logo from '@/assets/logo.png';
import mobileLogo from '@/assets/mobile-logo.png';
import API from '@/api/http'
import { SideOptions } from '@/plugins/components';
import { Popover, Button } from '@plugins/primevue-components';
import { DashboardIcon, DomainIcon, GameIcon, RewardIcon, TemplateIcon, UserDataIcon, LogoutIcon, SettingsIcon } from '@/plugins/icons';
import { markRaw } from "vue";

export default {
    name: 'SideMenu',
    components: {
        Popover,
        Button,
        DashboardIcon,
        DomainIcon,
        GameIcon,
        RewardIcon,
        TemplateIcon,
        UserDataIcon,
        LogoutIcon,
        SideOptions,
        SettingsIcon
    },
    computed: {
        access_modules() { return this.$store.getters['User/access_modules'] },
        user() { return this.$store.getters['User/user'] },
        user_limits() { return this.$store.getters['User/user_limits'] },
        shouldShowText() { return this.expand || this.currentWidth > this.breakpoint },
        showLoginElements() {
            return (
                this.user &&
                this.user.account_plan &&
                this.user.account_plan.status === 'paid' &&
                this.$route.name !== 'AddAccount' &&
                this.$route.name !== 'Welcome'
            )
        },
        sideStructured() {
            const structured = [];

            if (typeof this.access_modules['games'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'DA', label: 'Dashboard', action: () => this.$router.push({ name: 'Dashboard' }), icon: markRaw(DashboardIcon) });
            }

            if ((typeof this.access_modules['teams'] != 'undefined' && this.user.type == 'user') || this.user.type == 'account') {
                structured.push({ code: 'DO', label: 'Domains', action: () => this.$router.push({ name: 'Domains' }), icon: markRaw(DomainIcon) });
            }

            if (typeof this.access_modules['games'] != 'undefined' || this.user.type == 'account') {
                const daughterTemplates = [
                    { code: 'GO', label: 'Game Over', action: () => this.$router.push({ name: 'GameOverTemplateList' }) },
                    { code: 'SU', label: 'Success', action: () => this.$router.push({ name: 'SuccessTemplateList' }) },
                    { code: 'LE', label: 'Leaderboard', action: () => this.$router.push({ name: 'LeaderboardTemplateList' }) }
                ];
                structured.push({ code: 'TE', label: 'Templates', action: null, icon: markRaw(TemplateIcon), childrens: daughterTemplates });
            }

            if (typeof this.access_modules['games'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'GA', label: 'Games', action: () => this.$router.push({ name: 'GameList' }), icon: markRaw(GameIcon) });
            }

            if (typeof this.access_modules['rewards'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'RE', label: 'Rewards', action: () => this.$router.push({ name: 'RewardList' }), icon: markRaw(RewardIcon) });
            }

            if (this.user.plan.crm == 1) {
                structured.push({ code: 'UD', label: 'User Data', action: () => this.$router.push({ name: 'CRMList' }), icon: markRaw(UserDataIcon) });
            }

            const daughterSettings = [];
            if (typeof this.access_modules['integrations'] != 'undefined' || this.user.type == 'account') {
                daughterSettings.push({ code: 'IN', label: 'Integrations', action: () => this.$router.push({ name: 'Integrations' }) });
            }
            structured.push({ code: 'SE', label: 'Settings', action: null, icon: markRaw(SettingsIcon), childrens: daughterSettings });

            return structured;
        }
    },
    data() {
        return {
            mediaBaseURL: import.meta.env.VITE_MEDIA_BASE_URL,
            logo: logo,
            mobileLogo: mobileLogo,
            expand: false,
            resizeObserver: null,
            allowSidebarResizing: false,
            currentWidth: 0,
            sidebarExpandedWidth: '320px',
            sidebarCollapsedWidth: '80px',
            breakpoint: 1300,
        }
    },
    mounted() {
        this.addResizeObserver();
    },
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        logout() {
            API.logout().then(() => {
                this.$router.push({ name: 'Login' })
            }).catch(() => this.$router.push({ name: 'Login' }))
        },
        addResizeObserver() {
            /*
            this.resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    this.currentWidth = entry.contentRect.width;
                    if (this.currentWidth > this.breakpoint) {
                        this.allowSidebarResizing = false
                        this.expand = true
                    } else {
                        this.allowSidebarResizing = true
                    }
                }
            });

            this.resizeObserver.observe(document.getElementsByTagName('body')[0]);
            */
        },
        expandSidebar() {
            if (!this.allowSidebarResizing) return
            this.expand = !this.expand
        }
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
}
</script>

<style scoped>
#settingsMenu li .py-2,
#templatesMenu li .py-2,
.footer-item,
.popover__item,
.sidebar-profile {
    display: flex;
    align-items: center;
}

.popover {
    display: grid;
    padding: 12px;
    gap: 10px;
    min-width: 240px;
}

.popover__item {
    color: #989898;
    gap: 8px;
}

.admin-navbar {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
}

.sidebar-logo {
    padding: 6px;
}

.sidebar-profile {
    justify-content: space-between;
    gap: 6px;
    color: #000000;
    padding: 20px;
    border-top: 2px solid var(--separation-color);
    border-bottom: 2px solid var(--separation-color);
}

.sidebar__menu {
    height: calc(100vh - 88px);
}

.sidebar__footer {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}

#settingsMenu li .py-2,
#templatesMenu li .py-2 {
    gap: 10px;
    margin-left: 6px;
}

.pointer {
    border: 1px solid #C0C2C6;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}

.footer-item {
    width: 100%;
    gap: 10px;
}

.footer-item i {
    width: 22px;
    display: flex;
    justify-content: center;
}
</style>