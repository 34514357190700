import Storage from '@/helper/Storage'

const initialState = () => ({
  settings: {}
})

const state = initialState()

const actions = {

}

const getters = {
  settings (state) { return state.settings }
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_SETTINGS (state, settings) {
    Storage.Set('settings', settings)
    state.settings = settings
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
