const publicRoutes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Login'),
    alias: '/login',
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/register-team-mate/:id',
    name: 'RegisterTeamMate',
    component: () => import('@/views/RegisterTeamMate')
  },
  {
    path: '/game/:public_id',
    name: 'GamePad',
    component: () => import('@/views/GamePad'),
    meta: {
      onlyGuest: false
    }
  },
  {
    path: '/game-embedder/:public_id/:type?',
    name: 'GamePadEmbedder',
    component: () => import('@/views/GamePad'),
    meta: {
      onlyGuest: false
    }
  },
  {
    path: '/page-embedder/:public_id/:url_encoded/:game_id',
    name: 'PageEmbedder',
    component: () => import('@/views/PageEmbedder'),
    meta: {
      onlyGuest: false
    }
  },
  {
    path: '/rewards/:account_public_id/:player_public_id?',
    name: 'RewardsFront',
    component: () => import('@/views/Rewards'),
    props: (route) => ({
      'account_public_id': route.params.account_public_id,
      'player_id': route.params.player_public_id || null,
      'source_url': route.query.source_url || null
    }),
    meta: {
      onlyGuest: false
    }
  },
  {
    path: '/auth/callback/:provider',
    name: 'OauthCb',
    component: () => import('@/components/includes/OAuth'),
    props: (route) => ({
      'provider': route.params.provider,
      'state': route.query.state
    })
  }
]

export default publicRoutes
