<template>
  <nav
    class="navbar header-navbar pcoded-header iscollapsed mb-5"
    header-theme="theme1"
    pcoded-header-position="fixed"
  >
    <div class="navbar-wrapper" v-if="user.public_id">
      <!-- <div class="navbar-logo">
                <a class="mobile-menu" id="mobile-collapse" href="#!">
                    <i class="feather icon-menu icon-toggle-right"></i>
                </a>
                <router-link :to="{name: 'Dashboard'}" class="w-100">
                    <img :src="mediaBaseURL + 'assets/images/Klujo_logo_head.png'" alt="" class="w-75">
                </router-link>
                <a class="mobile-options">
                    <i class="feather icon-more-horizontal"></i>
                </a>
            </div> -->

      <div class="navbar-container">
        <ul class="nav-left float-left">
          <!-- <li class="site-logo">
                        <img :src="mediaBaseURL + 'assets/images/logo.png'" alt="">
                    </li> -->
          <!-- <li class="header-search">
                        Monthly Usage - {{ user.account_plan.monthly_total_impressions -  user.account_plan.monthly_remain_impressions }} out of {{ user.account_plan.monthly_total_impressions }} views
                    </li> -->
          <!-- <li>
                        <a href="#!" onclick="javascript:toggleFullScreen()">
                            <i class="feather icon-maximize full-screen"></i>
                        </a>
                    </li> -->
        </ul>
        <ul class="nav-right float-right">
          <li>
            <a
              class="btn btn-light btn-sm px-2 icon-shadow"
              href="https://klujo.freshdesk.com/support/home"
              target="_blank"
              ><i class="fa fa-question"></i
            ></a>
          </li>
          <li>
            <!-- <div class="CHANGE_THIS"></div> -->
            <button type="button" class="btn btn-light btn-sm icon-shadow CHANGE_THIS"
              ><i class="fa fa-bell"></i
            ></button>
          </li>
          <li
            v-if="
              (user.type == 'account' || user.type == 'child_account') &&
              ((Object.keys(user.related_accounts).length === 0 &&
                user.plan.sub_accounts > 1) ||
                user.related_accounts.length < user.plan.sub_accounts)
            "
          >
            <router-link
              class="btn btn-primary btn-sm icon-shadow"
              :to="{ name: 'AddAccount' }"
              ><i class="fa fa-user-plus"></i> Add Account</router-link
            >
          </li>
          <li class="user-profile header-notification">
            <div class="dropdown-primary dropdown">
              <div
                class="dropdown-toggle"
                data-toggle="dropdown"
                v-if="user.current_account"
              >
                <span class="text-primary">{{ user.current_account.business_name }}</span>
              </div>
              <ul
                class="show-notification profile-notification dropdown-menu"
                data-dropdown-in="fadeIn"
                data-dropdown-out="fadeOut"
              >
                <li v-for="(account, index) in user.related_accounts" :key="index">
                  <a href="javascript:void(0)" @click="changeAccount(account.public_id)">
                    <i class="las la-user-tie h5 pr-3"></i> {{ account.business_name }}
                  </a>
                </li>
                <li
                  :class="{ 'border-top': user.related_accounts.length > 0 }"
                  v-if="
                    (user.type == 'user' &&
                      typeof access_modules['account_managment'] != 'undefined') ||
                    user.type == 'account'
                  "
                >
                  <router-link :to="{ name: 'Upgrade' }">
                    <i class="las la-chart-bar h5 pr-3"></i> Upgrade
                  </router-link>
                </li>

                <li v-if="user.type == 'account'">
                  <router-link :to="{ name: 'Subscription' }">
                    <i class="las la-briefcase h5 pr-3"></i> Subscription
                  </router-link>
                </li>

                <li
                  v-if="
                    (typeof access_modules['teams'] != 'undefined' &&
                      user.type == 'user') ||
                    user.type == 'account'
                  "
                >
                  <router-link :to="{ name: 'TeamMates' }">
                    <i class="las la-users h5 pr-3"></i> Team Mates
                  </router-link>
                </li>
                <li
                  v-if="
                    (typeof access_modules['teams'] != 'undefined' &&
                      user.type == 'user') ||
                    user.type == 'account'
                  "
                >
                  <router-link :to="{ name: 'Domains' }">
                    <i class="las la-sitemap h5 pr-3"></i> Domains
                  </router-link>
                </li>

                <li class="border-top">
                  <a href="javascript:void(0);" @click="logout">
                    <i class="las la-sign-out-alt h5 pr-3"></i> Logout
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import API from '@/api/http'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Header',
  data () {
    return {
      mediaBaseURL: import.meta.env.VITE_MEDIA_BASE_URL
    }
  },
  computed: {
    user () {
      return this.$store.getters['User/user']
    },
    access_modules () {
      return this.$store.getters['User/access_modules']
    }
  },
  methods: {
    logout () {
      API.logout().then(() => {
        this.$router.push({ name: 'Login' })
      }).catch(() => this.$router.push({ name: 'Login' }))
    },
    changeAccount (publicUrl) {
      API.post('change-login/' + publicUrl).then((response) => {
        if (response.user) {
          API.setUser(response.user)
          this.$store.commit('Message/SET_MESSAGE', {
            message: response.message,
            type: 'success'
          })

          if (response.user.type === 'user') {
            this.$store.commit('User/SET_ACCESS_MODULES', response.access_modules)
          }
          if (response.limits) {
            this.$store.commit('User/SET_ACCOUNT_LIMITS', response.limits)
          }

          const accessModules = this.$store.getters['User/access_modules']
          if (
            typeof accessModules.full !== 'undefined' ||
              typeof accessModules.games !== 'undefined'
          ) {
            this.$router.push({ name: 'Dashboard' })
          } else {
            for (const key of Object.keys(accessModules)) {
              if (typeof this.GamesHelp.routes[key] !== 'undefined') {
                this.$router.push({ name: this.GamesHelp.routes[key] })
                break
              }
            }
          }

          if (this.$route.name !== 'Dashboard') {
            this.$router.push({ name: 'Dashboard' })
          }
        }
      })
    }
  }
}
</script>
