<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="4" fill="#0C3DE7" fill-opacity="0.1" />
        <g clip-path="url(#clip0_850_18994)">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M21.4444 15.3333C21.0981 15.3333 20.8081 15.216 20.5742 14.9813C20.3404 14.7467 20.223 14.4566 20.2222 14.1111V9.22222C20.2222 8.87593 20.3396 8.58585 20.5742 8.352C20.8089 8.11815 21.099 8.00081 21.4444 8H28.7778C29.1241 8 29.4146 8.11733 29.6492 8.352C29.8839 8.58667 30.0008 8.87674 30 9.22222V14.1111C30 14.4574 29.8827 14.7479 29.648 14.9826C29.4133 15.2172 29.1233 15.3341 28.7778 15.3333H21.4444ZM9.22222 30C8.87593 30 8.58585 29.8827 8.352 29.648C8.11815 29.4133 8.00081 29.1233 8 28.7778V23.8889C8 23.5426 8.11733 23.2525 8.352 23.0187C8.58667 22.7848 8.87674 22.6675 9.22222 22.6667H16.5556C16.9019 22.6667 17.1923 22.784 17.427 23.0187C17.6617 23.2533 17.7786 23.5434 17.7778 23.8889V28.7778C17.7778 29.1241 17.6604 29.4146 17.4258 29.6492C17.1911 29.8839 16.901 30.0008 16.5556 30H9.22222Z"
                fill="#0C3DE7" fill-opacity="0.5" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.22228 19.7C8.87598 19.7 8.58591 19.5827 8.35206 19.348C8.11821 19.1134 8.00087 18.8233 8.00006 18.4778V9.22225C8.00006 8.87595 8.11739 8.58588 8.35206 8.35202C8.58672 8.11817 8.8768 8.00084 9.22228 8.00002H16.5556C16.9019 8.00002 17.1924 8.11736 17.4271 8.35202C17.6617 8.58669 17.7787 8.87676 17.7778 9.22225V18.4778C17.7778 18.8241 17.6605 19.1146 17.4258 19.3492C17.1912 19.5839 16.9011 19.7008 16.5556 19.7H9.22228ZM21.4444 30C21.0981 30 20.8081 29.8827 20.5742 29.648C20.3404 29.4133 20.223 29.1233 20.2222 28.7778V19.8222C20.2222 19.4759 20.3396 19.1859 20.5742 18.952C20.8089 18.7182 21.099 18.6008 21.4444 18.6H28.7778C29.1241 18.6 29.4146 18.7174 29.6492 18.952C29.8839 19.1867 30.0008 19.4768 30 19.8222V28.7778C30 29.1241 29.8827 29.4146 29.648 29.6492C29.4133 29.8839 29.1233 30.0008 28.7778 30H21.4444Z"
                fill="#0C3DE7" />
        </g>
        <defs>
            <clipPath id="clip0_850_18994">
                <rect width="22" height="22" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'DashboardIcon',
}
</script>