<template>
  <div v-if="show">
    <div>
      Please Wait...
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data () {
    return {
      show: false
    }
  },
  watch: {
    loading (newValue) {
      this.show = newValue
    }
  },
  computed: {
    loading () { return this.$store.getters['Loading/loading'] }
  }
}
</script>
