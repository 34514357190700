import axios from 'axios'
import app from '@/main'
import store from '@/store'

const API = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
  // timeout: 2000
})

const authInterceptor = (config) => {
  if (store.getters['User/user'].token) {
    config.headers.Authorization = 'Bearer ' + store.getters['User/user'].token
  }
  if (store.getters['User/user'].current_account) {
    config.headers['X-ACCOUNT'] = store.getters['User/user'].current_account.public_id
  }
  return config
}
API.interceptors.request.use(authInterceptor)

API.interceptors.request.use(config => {
  app.$Progress.start()
  return config
})

API.interceptors.response.use(response => {
  app.$Progress.finish()
  return response
})


API.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('reset')
    // window.location = import.meta.env.VITE_BASE_URL
  } else {
    return Promise.reject(error)
  }
})

export default API
