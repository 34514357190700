<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="4" fill="#6D56E6" fill-opacity="0.1" />
        <g clip-path="url(#clip0_850_17766)">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8.00054 22.102C7.98914 22.4988 8.15882 22.8194 8.50957 23.0639L17.3881 29.4806C17.8703 29.8269 18.4074 30 18.9993 30C19.5912 30 20.1283 29.8269 20.6106 29.4806L25.0499 26.2722L29.4891 23.0639C29.8407 22.8186 30.0109 22.4976 29.9995 22.1008C29.9881 21.704 29.807 21.3833 29.4562 21.1389C29.2151 20.9759 28.952 20.8944 28.667 20.8944C28.382 20.8944 28.119 20.9759 27.8778 21.1389L18.9993 27.525L10.1209 21.1389C9.87971 20.9759 9.61664 20.8944 9.33165 20.8944C9.04667 20.8944 8.7836 20.9759 8.54246 21.1389C8.19258 21.3841 8.01194 21.7052 8.00054 22.102Z"
                fill="#6D56E6" fill-opacity="0.5" />
            <path
                d="M17.3881 23.3083L9.8249 17.8389C9.14532 17.35 8.80552 16.7083 8.80552 15.9139C8.80552 15.1194 9.14532 14.4778 9.8249 13.9889L17.3881 8.51944C17.8703 8.17315 18.4074 8 18.9993 8C19.5912 8 20.1283 8.17315 20.6106 8.51944L28.1738 13.9889C28.8534 14.4778 29.1932 15.1194 29.1932 15.9139C29.1932 16.7083 28.8534 17.35 28.1738 17.8389L20.6106 23.3083C20.1283 23.6546 19.5912 23.8278 18.9993 23.8278C18.4074 23.8278 17.8703 23.6546 17.3881 23.3083Z"
                fill="#6D56E6" />
        </g>
        <defs>
            <clipPath id="clip0_850_17766">
                <rect width="22" height="22" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'TemplateIcon',
}
</script>