import Storage from '@/helper/Storage'

const initialState = () => ({
  errors: {},
  isLoggedin: false,
  user: {
    full_name: 'John Doe',
    score: 999,
    played: 0
  }
})

const state = initialState()

const actions = {
  getFBUser ({ state, commit }) {
    try {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const FBUser = await Storage.Get('FBUser', state.user, 'object')
        commit('SET_FB_LOGIN_STATUS', Storage.Get('isFBLoggedin', false, 'bool'))
        commit('SET_FB_USER', FBUser)
        resolve(FBUser)
      })
    } catch (error) {
      console.log({ error })
    }
  }
}

const getters = {
  FBuser (state) { return state.user },
  isFBLoggedin (state) { return state.isLoggedin }
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_FB_USER (state, user) {
    Storage.Set('FBUser', user)
    localStorage?.setItem('KlujoFBUser', JSON.stringify(user))
    state.user = user
  },
  SET_FB_LOGIN_STATUS (state, flag) {
    Storage.Set('isFBLoggedin', flag)
    state.isLoggedin = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
