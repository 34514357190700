import axios from 'axios'
import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';
// import Toasted from 'vue-toasted'
import { UniversalSocialauth } from 'universal-social-auth'

// Import custom CSS file
import './assets/css/style.scss'
import 'font-awesome/css/font-awesome.min.css'
import 'primeicons/primeicons.css';

// VueQuillEditor styles
// import '@vueup/vue-quill/dist/vue-quill.bubble.css'
// import '@vueup/vue-quill/dist/vue-quill.core.css'
// import 'quill/dist/quill.snow.css'
import VueConfetti from 'vue-confetti'
import GamesHelper from './helper/Games'

// Import Bootstrap
import * as bootstrap from 'bootstrap'
import VueProgressBar from '@aacassandra/vue3-progressbar'

const authOptions = {
  cookieStorage: {
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    path: '/',
    secure: true
  },
  providers: {
    google: {
      clientId: import.meta.env.VITE_GOOG_PUBLIC_ID,
      redirectUri: `${import.meta.env.VITE_API_BASE_URL}auth/callback/google`,
      url: '/auth/callback/google',
      requiredUrlParams: ['state', 'scope']
    },
    facebook: {
      authorizationEndpoint: 'https://www.facebook.com/v17.0/dialog/oauth',
      clientId: import.meta.env.VITE_FB_PUBLIC_ID,
      redirectUri: `${import.meta.env.VITE_API_BASE_URL}auth/callback/facebook`,
      url: '/auth/callback/facebook',
      display: 'popup',
      requiredUrlParams: ['state', 'display']
    },
    linkedin: {
      clientId: import.meta.env.VITE_LN_PUBLIC_ID,
      redirectUri: `${import.meta.env.VITE_API_BASE_URL}auth/callback/linkedin`,
      scope: 'profile%20email',
      url: '/auth/callback/linkedin'
    }
  }
}

const KlujoPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '#9D76D5',
      100: '#8758D0',
      200: '#7844CB',
      300: '#6A30C6',
      400: '#5C1CB8',
      500: '#4A3494',
      600: '#3C2A7F',
      700: '#2F216A',
      800: '#23185A',
      900: '#171246',
      950: '#0D0A3C'
    }
  }
});

const app = createApp(App)
app.config.globalProperties.$oauth = new UniversalSocialauth(axios, authOptions)
app.config.productionTip = app.config.devtools = !!(import.meta.env.NODE_ENV === 'development')
export default app.use(router)
  .use(store)
  .use(VueConfetti)
  .use(VueAxios, axios)
  .use(() => bootstrap)
  .use(() => GamesHelper.editor)
  .use(PrimeVue, {
    theme: {
      preset: KlujoPreset,
      options: {
        primary: '#4A3494',
        darkModeSelector: false
      }
    }
  })
  .use(ConfirmationService)
  .use(VueProgressBar, {thickness: '3px', color: '#29d'})
  .mount('#app')

// app.use(Toasted) //VueQuillEditor),
// $(document.body).draggable()
