import { createRouter, createWebHistory } from 'vue-router'
import publicRoutes from './public'
import privateRoutes from './private'
import store from '@/store'
import GamesHelper from '@/helper/Games'

const routes = publicRoutes.concat(privateRoutes)

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    store.dispatch('User/getAuth')
      .then((user) => {
        if (user && user.account_plan && user.account_plan.status === 'pending_payment' && to.name !== 'Payment') {
          next({ name: 'Payment' })
        } else if (user) {
          if (user.type === 'user') {
            store.dispatch('User/getAccessModules')
              .then((accessModules) => {
                if (to.meta.module && accessModules[to.meta.module]) {
                  next()
                } else {
                  for (const key of Object.keys(accessModules)) {
                    if (typeof GamesHelper.routes[key] !== 'undefined') {
                      next({ name: GamesHelper.routes[key] })
                      break
                    }
                  }
                  return false
                }
              })
          } else {
            next()
          }
        } else {
          next({ name: 'Login' })
        }
      }).catch((error) => {
        store.commit('Message/SET_MESSAGE', {
          message: error.message,
          type: 'warning'
        })
        store.commit('User/SET_LOGIN_SUCCESS_ROUTE', to.name)
        next({ name: 'Login' })
      })
  } else if (to.matched.some(route => route.meta.onlyGuest)) {
    store.dispatch('User/setAuthFromLocal')
      .then(() => {
        if (store.getters['User/isLoggedin']) {
          next({ name: 'Dashboard' })
        } else {
          next()
        }
      })
  } else {
    if (store.getters['User/isFirstLoad']) {
      store.commit('User/SET_FIRST_LOAD_STATUS', false)
    }
    next()
  }
})

export default router
